import React, { memo, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
// import Form from 'react-bootstrap/Form';
import {
  Row,
  Col,
  Image,
  Button,
  Card,
  Alert,
  Badge,
  NavDropdown,
} from 'react-bootstrap';
import { Dialog, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { openDB } from 'idb';

import { toast } from 'react-toastify';
import { isAndroid, isIOS } from 'react-device-detect';
import { trackPromise } from 'react-promise-tracker';

import Toaster from '../../components/Toaster';
import Ellipses from '../../components/Ellipses';

import { Desktop, Mobile } from '../media-queries/mediaQueries';

import './css/addNewTimesheet.css';
import {
  deleteTimesheetById,
  getTimesheetByTimesheetId,
  updateTimesheetById,
} from '../../services/timesheet.service';
import {
  checkTimesheetDeletable,
  checkTimesheetEditable,
  DateConvert,
  minuteToTime,
  minuteToTimeFullStr,
  showTimesheetLastUpdated,
  checkShowAcknowledgeButton,
} from '../../utils/TimesheetManagement';

import ConfirmAlertModal from '../../components/ConfirmAlertModal/ConfirmAlertModal';
import { useAuthContext } from '../../contexts/user-context';
import useNavigator from '../../utils/CustomHooks/useNavigator';
// import IconTrue from '../../assets/images/icon-true.svg';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewTimesheet = memo(() => {
  const history = useHistory();

  // custom hook for checking network status
  const isOnline = useNavigator();

  const { timesheetId } = useParams();
  const { authUser } = useAuthContext();

  const [isAPICalled, setAPICalled] = useState(false);
  const [timesheet, setTimesheet] = useState(null);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [paymentDialogDetails, setPaymentDialogDetails] = useState({
    open: false,
    error: '',
    isPaymentSuccessfull: false,
  });

  const isOfficeUser = authUser.user.role.rolename !== 'Office';
  const isAccountantUser = authUser.user.role.rolename !== 'Accountant';
  // const isChefOrOfficeUser = authUser.user.role.rolename === 'Chef' || authUser.user.role.rolename === 'Office';

  const handleCloseDialog = () => {
    setPaymentDialogDetails({
      ...paymentDialogDetails,
      open: false,
    });
  };

  const onHandleBack = () => {
    history.push('/timesheet');
    // window.close();
    // window.opener = null;
    // window.open('', '_self');
    // window.close();
  };

  const onEditTimesheet = () => {
    history.push(`/editTimesheet/${timesheetId}`);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function getTimesheetDetails() {
    try {
      if (isOnline) {
        const response = await trackPromise(
          getTimesheetByTimesheetId(timesheetId)
        );
        setTimesheet(response.data.data.timesheet);
        setIsAcknowledged(response.data.data.timesheet.isAcknowledged);
        setAPICalled(true);
      } else {
        const dbs = await openDB('order', 1, {
          upgrade(db) {
            // Create a store of objects
            const store = db.createObjectStore('order_requests', {
              // The 'uniqueId' property of the object will be the key.
              keyPath: 'uniqueId',
              // If it isn't explicitly set, create a value by auto incrementing.
              // autoIncrement: true,
            });
            // Create an index on the 'date' property of the objects.
            store.createIndex('uniqueId', 'uniqueId');
          },
        });
        const timesheetDetails = await dbs.get(
          'order_requests',
          IDBKeyRange.only(timesheetId)
        );

        if (!timesheetDetails) {
          history.replace('/page-not-found');
          return;
        }
        setTimesheet(timesheetDetails);
        setAPICalled(true);
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    }
  }

  const acknowledge = async () => {
    const response = await trackPromise(
      getTimesheetByTimesheetId(timesheet.timesheetId)
    ); // Fetch the current timesheet data from the server

    if (response.status === 200) {
      const updatedTimesheet = response.data.data.timesheet;

      // Check if the timesheet is already acknowledged
      if (updatedTimesheet.isAcknowledged === true) {
        toast.error(
          <Toaster
            icon="error"
            message={`This timesheet is already acknowledged by ${updatedTimesheet.acknowledgedBy.username}`}
          />
        );
        setIsAcknowledged(true);
      } else {
        // Add the new fields and set their values
        updatedTimesheet.isAcknowledged = true;
        updatedTimesheet.acknowledgedBy = authUser.user._id;
        updatedTimesheet.acknowledgedTimestamp = new Date();

        const saveResponse = await trackPromise(
          updateTimesheetById(updatedTimesheet.timesheetId, updatedTimesheet)
        );
        if (saveResponse.status === 200) {
          toast.dark(
            <Toaster icon="notify" message="Acknowledged successfully" />
          );
          setIsAcknowledged(true);
        }
      }
    }
  };

  useEffect(() => {
    getTimesheetDetails();

    const { location } = history;
    const { state } = location;
    if (state && state.from === 'paymentHolding') {
      setPaymentDialogDetails({
        open: true,
        error: state.error,
        isPaymentSuccessfull: state.success,
      });
    }
  }, [timesheetId]);

  const onDeleteTimesheet = async () => {
    try {
      if (isOnline) {
        const response = await trackPromise(deleteTimesheetById(timesheetId));
        toast.dark(
          <Toaster icon="notify" message={`${response.data.message}`} />,
          { autoClose: 3000, hideProgressBar: true }
        );
      } else {
        const db = await openDB('order');
        await db.delete('order_requests', IDBKeyRange.only(timesheetId));
        toast.dark(
          <Toaster
            icon="notify"
            message="Timesheet details removed from local data."
          />,
          { autoClose: 3000, hideProgressBar: true }
        );
      }
    } catch (error) {
      toast.dark(
        <Toaster
          icon="error"
          message={error?.response?.data?.message ?? 'Something went wrong!!!'}
        />
      );
    } finally {
      setShow(false);
      history.push('/timesheet');
    }
  };
  const copyText = () => {
    const employeeName = timesheet.employee.username;
    const clientName = timesheet.client?.username;

    const note = `${timesheet.uniqueId}:
    Employee - ${employeeName}
    ${clientName ? `Client - ${clientName}` : ''}
    Location - ${timesheet.service_city.cityname}`;

    navigator.clipboard.writeText(note);

    toast.dark(<Toaster icon="notify" message="Copied to clipboard" />);
  };
  const handlePaymentProcess = async () => {
    // check if payment is already done

    const response = await trackPromise(getTimesheetByTimesheetId(timesheetId));
    const paymentStatus = response.data.data.timesheet.payment_status;
    if (paymentStatus === 'paid') {
      setTimesheet(response.data.data.timesheet);
      return;
    }
    // amount in cents
    let totalAmount = parseFloat(timesheet.totalDue.toFixed(2)) * 100;

    totalAmount = parseInt(totalAmount, 10);

    const employeeName = timesheet.employee.username;
    const clientName = timesheet.client?.username;
    const callbackUrl = process.env.REACT_APP_CALLBACK_URL;

    // Your application ID
    const applicationId = process.env.REACT_APP_SQUARE_APP_ID;

    // The total and currency code should come from your transaction flow.
    const transactionTotal = `${totalAmount}`;
    const currencyCode = 'USD';

    // The version of the Point of Sale SDK that you are using.
    const sdkVersion = 'v2.0';

    const note = `${timesheet.uniqueId}:
      Employee - ${employeeName}
      ${clientName ? `Client - ${clientName}` : ''}
      Location - ${timesheet.service_city.cityname}`;

    const returnTimeOut = 3200;

    if (isAndroid) {
      const posUrl =
        'intent:#Intent;' +
        'action=com.squareup.pos.action.CHARGE;' +
        'package=com.squareup;' +
        `S.com.squareup.pos.WEB_CALLBACK_URI=${callbackUrl};` +
        `S.com.squareup.pos.CLIENT_ID=${applicationId};` +
        `S.com.squareup.pos.API_VERSION=${sdkVersion};` +
        `i.com.squareup.pos.TOTAL_AMOUNT=${transactionTotal};` +
        `S.com.squareup.pos.CURRENCY_CODE=${currencyCode};` +
        `S.com.squareup.pos.NOTE=${note};` +
        `l.com.squareup.pos.AUTO_RETURN_TIMEOUT_MS=${returnTimeOut};` +
        `S.com.squareup.pos.REQUEST_METADATA=${timesheetId};` +
        `S.com.squareup.pos.TENDER_TYPES=com.squareup.pos.TENDER_CARD,com.squareup.pos.TENDER_CASH,com.squareup.pos.TENDER_CARD_ON_FILE,com.squareup.pos.TENDER_OTHER;end`;

      window.open(posUrl);
    } else if (isIOS) {
      const dataParameter = {
        amount_money: {
          amount: transactionTotal,
          currency_code: 'USD',
        },

        // Replace this value with your application's callback URL
        callback_url: callbackUrl,

        // Replace this value with your application's ID
        client_id: applicationId,

        version: '1.3',

        notes: `${note}`,

        state: `${timesheetId}`,
      };
      if (
        process.env.NODE_ENV === 'staging' ||
        process.env.NODE_ENV === 'production'
      ) {
        dataParameter.options = {
          supported_tender_types: [
            'CREDIT_CARD',
            'CASH',
            'OTHER',
            'SQUARE_GIFT_CARD',
            'CARD_ON_FILE',
          ],
          auto_return: true,
        };
      } else if (process.env.NODE_ENV === 'development') {
        dataParameter.options = {
          supported_tender_types: ['CASH'],
          auto_return: true,
        };
      }
      window.location = `square-commerce-v1://payment/create?data=${encodeURIComponent(
        JSON.stringify(dataParameter)
      )}`;
    } else {
      alert('Unsupported device');
    }
  };

  const { isPaymentSuccessfull, error, open } = paymentDialogDetails;
  return isAPICalled ? (
    <div>
      <div className="sticky-page-header">
        <div className="container">
          <Desktop>
            <Row>
              <Col md="1">
                <a
                  onKeyPress={() => {}}
                  role="link"
                  tabIndex="0"
                  onClick={onHandleBack}
                >
                  <Image
                    className="imageArrow"
                    src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                    fluid
                  />
                </a>
              </Col>
              <Col md="8" className="pl-0">
                <h5 className="m-0">
                  <strong>
                    Timesheet {isOnline && timesheet.timesheetId ? '-' : ''}
                  </strong>{' '}
                  {timesheet.timesheetId}
                </h5>
                {showTimesheetLastUpdated(authUser.user.role.rolename) && (
                  <label className="txt-light-secondary">
                    Last Updated on {DateConvert(timesheet.updatedAt)}
                  </label>
                )}
              </Col>
              <Col md="3">
                <Row>
                  <Col className="pr-0 ml-5">
                    <Button
                      variant="default"
                      onClick={onEditTimesheet}
                      className="btn-ftc-border-primary ml-5"
                      disabled={
                        !checkTimesheetEditable(
                          authUser.user.role.rolename,
                          authUser.user.role.permissions,
                          timesheet.payment_status,
                          timesheet.ts_date
                        )
                      }
                      // disabled={timesheet.payment_status === 'paid'}
                    >
                      <strong>Edit</strong>
                    </Button>
                  </Col>
                  <Col className="pl-0 mr-0 pr-0">
                    <NavDropdown
                      title={<Ellipses />}
                      id="collasible-nav-dropdown"
                      className="float-right pr-0 viewTimesheetEllipses"
                    >
                      <NavDropdown.Item
                        onClick={handleShow}
                        disabled={
                          !checkTimesheetDeletable(
                            authUser.user.role.rolename,
                            authUser.user.role.permissions,
                            timesheet.payment_status,
                            timesheet.ts_date
                          )
                        }
                      >
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Col>
                </Row>
              </Col>
              {/* <Col md="1">
                        
              </Col> */}
            </Row>
          </Desktop>
          <Mobile>
            <div className="container">
              <Row className="border-bottom pl-0 pr-0 pb-0">
                <Col xs="1" className="p-0">
                  {/* <Link to="/timesheet">
                    <Image
                      className="imageArrowMobile ml-2"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </Link> */}
                  <a
                    onKeyPress={() => {}}
                    role="link"
                    tabIndex="0"
                    onClick={onHandleBack}
                  >
                    <Image
                      className="imageArrowMobile ml-2"
                      src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-down-arrow.svg`}
                      fluid
                    />
                  </a>
                </Col>
                <Col xs="9" className="mt-1">
                  <h6 className="m-auto">
                    <strong>
                      Timesheet {isOnline && timesheet.timesheetId ? '-' : ''}
                    </strong>
                    <label className="mb-0">{timesheet.timesheetId}</label>
                  </h6>
                  {showTimesheetLastUpdated(authUser.user.role.rolename) && (
                    <label className="txt-light-secondary mx-auto">
                      Last Updated on {DateConvert(timesheet.updatedAt)}
                    </label>
                  )}
                </Col>
                <Col xs="1">
                  <div>
                    <NavDropdown
                      title={<Ellipses />}
                      id="collasible-nav-dropdown"
                    >
                      <NavDropdown.Item
                        onClick={handleShow}
                        disabled={
                          !checkTimesheetDeletable(
                            authUser.user.role.rolename,
                            authUser.user.role.permissions,
                            timesheet.payment_status,
                            timesheet.ts_date
                          )
                        }
                      >
                        Delete
                      </NavDropdown.Item>
                    </NavDropdown>
                  </div>
                  {/* <DropdownButton as={ButtonGroup} title="Settings">
                <Dropdown.Item eventKey="1" onClick={handleShow}>Delete</Dropdown.Item>
              </DropdownButton> */}
                  {/* <Button variant="default" className="imageDots"><Image className="imageDots" src={process.env.PUBLIC_URL + "/assets/svg-icons/three-dots-vertical.svg"} fluid/></Button>                         */}
                </Col>
              </Row>
            </div>
          </Mobile>
        </div>
      </div>
      <Desktop>
        <div className="container-top-8" />
      </Desktop>
      <Mobile>
        <div className="container-top-20" />
        <br />
      </Mobile>
      <div className="container">
        <Row>
          <Col md="7">
            <Card className="mb-4">
              <Card.Body className="pb-0">
                <Row>
                  <Col md="10" xs="8" className="d-flex pl-0">
                    <h5>
                      <Badge pill variant="light">
                        1
                      </Badge>
                    </h5>
                    <strong className="ml-3">
                      <h5 className="txt-primary">Basic Details</h5>
                    </strong>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">Employee Name</label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right">
                      <strong>{timesheet.employee.username}</strong>
                    </label>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="4" className="p-0">
                    <label className="txt-light-secondary">Service City</label>
                  </Col>
                  <Col md="7" xs="8" className="p-0">
                    <label className="float-right">
                      <strong>{timesheet.service_city.cityname}</strong>
                    </label>
                  </Col>
                </Row>
                {isOfficeUser && (
                  <Row>
                    <Col md="5" xs="6" className="p-0">
                      <label className="txt-light-secondary">Client Name</label>
                    </Col>
                    <Col md="7" xs="6" className="p-0">
                      <label className="float-right">
                        {timesheet.client?.username ? (
                          <span>
                            <strong>{timesheet.client?.username}</strong>{' '}
                            <span className="d-inline-flex">
                              ({timesheet.client?.clientId})
                            </span>
                          </span>
                        ) : (
                          <span>Not Provided</span>
                        )}
                      </label>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
            <Card className="mb-4">
              <Card.Body className="pb-0">
                <Row>
                  <Col md="10" xs="8" className="d-flex pl-0">
                    <h5>
                      <Badge pill variant="light">
                        2
                      </Badge>
                    </h5>
                    <strong className="ml-3">
                      <h5 className="txt-primary">Date & Time</h5>
                    </strong>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">Date</label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right">
                      <strong>{DateConvert(timesheet.ts_date)}</strong>
                    </label>
                  </Col>
                </Row>
                {isOfficeUser && (
                  <Row className="border-bottom">
                    <Col md="5" xs="6" className="p-0">
                      <label className="txt-light-secondary">Drive Time</label>
                    </Col>
                    <Col md="7" xs="6" className="p-0">
                      <label className="float-right">
                        {timesheet?.driveTime ? (
                          <strong>{timesheet.driveTime} Minutes</strong>
                        ) : (
                          'N/A'
                        )}
                      </label>
                    </Col>
                  </Row>
                )}

                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">Arrive Time</label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right">
                      {timesheet?.arriveTime || timesheet.arriveTime === 0 ? (
                        <strong>{minuteToTime(timesheet.arriveTime)}</strong>
                      ) : (
                        'N/A'
                      )}
                    </label>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">Depart Time</label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right">
                      {timesheet?.departureTime ||
                      timesheet.departureTime === 0 ? (
                        <strong>{minuteToTime(timesheet.departureTime)}</strong>
                      ) : (
                        'N/A'
                      )}
                    </label>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">
                      Flex Pay Hours Time
                    </label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right txt-primary">
                      {timesheet?.flexPayHours ? (
                        <strong>
                          {timesheet?.flexPayHours.toFixed(2)} Hrs
                        </strong>
                      ) : (
                        <p className="txt-light-secondary">Not Specified</p>
                      )}
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">Total Time</label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right txt-primary">
                      <strong>
                        {minuteToTimeFullStr(timesheet.totalTime)}
                      </strong>
                    </label>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md="5">
            <Card>
              <Card.Body className="pb-0">
                <Row>
                  <Col md="10" xs="8" className="d-flex pl-0">
                    <h5>
                      <Badge pill variant="light">
                        3
                      </Badge>
                    </h5>
                    <strong className="ml-3">
                      <h5 className="txt-primary">Charges</h5>
                    </strong>
                  </Col>
                </Row>
                {isOfficeUser && (
                  <>
                    <Row className="border-bottom">
                      <Col md="7" xs="9" className="p-0">
                        <label className="txt-light-secondary">
                          Holding Fee Number
                        </label>
                      </Col>
                      <Col md="5" xs="3" className="p-0">
                        <label className="float-right">
                          <strong>{timesheet.holding_fee_number}</strong>
                        </label>
                      </Col>
                    </Row>
                    <Row className="border-bottom">
                      <Col md="5" xs="6" className="p-0">
                        <label className="txt-light-secondary">Tip</label>
                      </Col>
                      <Col md="7" xs="6" className="p-0">
                        <label className="float-right">
                          <strong>$ {timesheet?.tip ?? '0.00'}</strong>
                        </label>
                      </Col>
                    </Row>
                    <Row className="border-bottom">
                      <Col md="7" xs="6" className="p-0">
                        <label className="txt-light-secondary">
                          Current Holding Fee
                        </label>
                      </Col>
                      <Col md="5" xs="6" className="p-0">
                        <label className="float-right">
                          <strong>$ {timesheet.current_holding_fee}</strong>
                        </label>
                      </Col>
                    </Row>
                  </>
                )}

                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">Rate Plan</label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right">
                      <strong>{timesheet.choose_rate}</strong>
                    </label>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">Rate</label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right">
                      <strong>$ {timesheet.rate}</strong>
                    </label>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="9" xs="9" className="p-0">
                    <label className="txt-light-secondary">Adjustment</label>
                    {'adjustment' in timesheet &&
                      timesheet.adjustment !== null &&
                      timesheet.adjustment !== 0 &&
                      checkShowAcknowledgeButton(
                        authUser.user.role.rolename,
                        authUser.user.role.permissions,
                        isAcknowledged,
                        moment(timesheet.ts_date)
                          .add(1, 'days')
                          .format('YYYY-MM-DD'),
                        timesheet?.acknowledgedBy?.username,
                        acknowledge
                      )}
                  </Col>
                  <Col md="3" xs="3" className="p-0">
                    <label className="float-right">
                      <strong>$ {timesheet?.adjustment ?? '0.00'}</strong>
                    </label>
                  </Col>
                </Row>
                <Row className="border-bottom">
                  <Col md="5" xs="6" className="p-0">
                    <label className="txt-light-secondary">Total Due</label>
                  </Col>
                  <Col md="7" xs="6" className="p-0">
                    <label className="float-right txt-primary">
                      <strong>$ {timesheet.totalDue.toFixed(2)}</strong>
                    </label>
                  </Col>
                </Row>
                {timesheet.trainer ? (
                  <Row>
                    <Col md="12" xs="12">
                      <span className="d-flex justify-content-center txt-primary">
                        <strong>This was Trainer time.</strong>
                      </span>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}

                {timesheet.comments && (
                  <Row className="border-bottom">
                    <Col md="12" xs="12" className="p-0">
                      <label className="txt-light-secondary">Comment</label>
                    </Col>
                    <Col md="12" xs="12" className="p-0">
                      <div
                        style={{
                          backgroundColor: '#FBFBFB',
                        }}
                        className="pl-2 pr-2 pb-3 pt-1"
                      >
                        {timesheet.comments}
                      </div>
                    </Col>
                  </Row>
                )}

                <Row className="p-0 mt-3">
                  <Col md="12" xs="12" className="p-0">
                    {timesheet.payment_status === 'pending' && (
                      <Alert variant="warning">
                        <Col md="12" xs="12" className="p-0">
                          <strong>Payment Status</strong>
                          <span className="float-right">
                            <strong>Pending</strong>
                            <Image
                              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-alert.svg`}
                            />
                          </span>
                        </Col>
                      </Alert>
                    )}

                    {timesheet.payment_status === 'paid' && (
                      <Alert variant="success">
                        <Col md="12" xs="12" className="p-0">
                          <strong>Payment Status</strong>
                          <span className="float-right">
                            <strong>Paid</strong>
                            <Image
                              src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-check.svg`}
                            />
                          </span>
                        </Col>
                      </Alert>
                    )}
                  </Col>
                </Row>
                <Mobile>
                  {isOnline ? (
                    <>
                      {isAccountantUser &&
                        timesheet.payment_status === 'pending' && (
                          <>
                            <Row className="pt-0 pl-0 pr-0">
                              <Col xs="12" className="p-0">
                                <button
                                  type="button"
                                  onClick={handlePaymentProcess}
                                  className="btn btn-ftc-primary  btn-block"
                                >
                                  Proceed without Discount
                                </button>
                              </Col>
                            </Row>
                            <Row className="pt-0 pl-0 pr-0">
                              <Col xs="12" className="p-0">
                                <button
                                  type="button"
                                  onClick={copyText}
                                  className="btn btn-block btn btn-default btn-ftc-border-primary"
                                >
                                  Copy for Discount
                                  <Image
                                    src={`${process.env.PUBLIC_URL}/assets/discount.png`}
                                    className="discount-icon ml-2"
                                  />
                                </button>
                              </Col>
                            </Row>
                          </>
                        )}
                    </>
                  ) : (
                    ''
                  )}

                  <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    maxWidth="md"
                    fullWidth="true"
                  >
                    <DialogTitle className="pb-0">
                      <div className="float-right">
                        <Image
                          onClick={handleCloseDialog}
                          className="ftc-logo-24"
                          src={`${process.env.PUBLIC_URL}/assets/svg-icons/icon-close.svg`}
                          fluid
                        />
                      </div>
                    </DialogTitle>
                    <DialogContent>
                      <div>
                        {!isPaymentSuccessfull ? (
                          <>
                            <Row className="pb-0">
                              <Col xs="12" className="text-center">
                                <Image
                                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/payment-fail.svg`}
                                  fluid
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" className="text-center">
                                <label className="font-24">
                                  <strong>Payment Failed</strong>
                                </label>
                              </Col>
                              <Col xs="12" className="text-center">
                                {error}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" className="pl-0 pr-2">
                                <button
                                  type="button"
                                  onClick={handleCloseDialog}
                                  className="btn btn-ftc-border-primary btn-block"
                                >
                                  Close
                                </button>
                              </Col>
                              {/* <Col xs="6" className="pr-0 pl-2">
                                    <button type="button" onClick={handleCloseDialog} className="btn btn-ftc-primary btn-block">Retry</button>
                                  </Col> */}
                            </Row>
                          </>
                        ) : (
                          <>
                            <Row className="pb-0">
                              <Col xs="12" className="text-center">
                                <Image
                                  src={`${process.env.PUBLIC_URL}/assets/svg-icons/payment-success.svg`}
                                  fluid
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12">
                                <label className="text-center font-24">
                                  <strong>Your Payment Was Successful.</strong>
                                </label>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" className="p-0">
                                <button
                                  type="button"
                                  onClick={handleCloseDialog}
                                  className="btn btn-ftc-primary btn-block"
                                >
                                  Okay
                                </button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </div>
                    </DialogContent>
                  </Dialog>
                </Mobile>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Mobile>
          <Row className="mt-2 border-top-shadow">
            <Col xs={12} className="pr-2">
              <Button
                variant="default"
                onClick={onEditTimesheet}
                className="float-right btn-ftc-border-primary col-3"
                disabled={
                  !checkTimesheetEditable(
                    authUser.user.role.rolename,
                    authUser.user.role.permissions,
                    timesheet.payment_status,
                    timesheet.ts_date
                  )
                }
              >
                <strong>Edit</strong>
              </Button>

              {!isOnline && timesheet.payment_status === 'pending' && (
                <Button
                  variant="default"
                  onClick={copyText}
                  className="float-right bg-transparent border-0 txt-primary mr-3"
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/assets/copy.png`}
                    className="copy-icon mr-1"
                  />
                  Copy Data
                </Button>
              )}
            </Col>
          </Row>
        </Mobile>
      </div>
      {show && (
        <ConfirmAlertModal
          show={show}
          handleClose={handleClose}
          title="Delete Timesheet"
          description="Deleting this timesheet will erase all data inside this record.
                Are you sure you want to delete this timesheet?"
          callback={onDeleteTimesheet}
          action="Delete it"
          closeAction="Cancel"
        />
      )}
    </div>
  ) : (
    ''
  );
});

export default ViewTimesheet;
